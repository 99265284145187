export const defaultChainName = 'juno'
export const tokenHeaderName = 'x-admin-token'
export const adminAddressHeaderName = 'x-admin-address'

export const chainEndpoint = {
  juno: {
    rpc: ['https://rpc-juno.itastakers.com', 'https://rpc-juno.whispernode.com:443'],
    rest: ['https://lcd-juno.itastakers.com', 'https://lcd-juno.whispernode.com:443'],
  },
}

export const kleomedesMoniker = 'kleomedes'
