'use client'

import { As, ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { SignerOptions } from '@cosmos-kit/core'
import { getSigningCosmosClientOptions } from 'juno-network'
import { GasPrice } from '@cosmjs/stargate'
import { chainEndpoint, kleoTheme } from '../../config'
import { ChainProvider } from '@cosmos-kit/react'
import { assets, chains } from 'chain-registry'
import { AssetList } from '@chain-registry/types'
import { wallets as keplrWallets } from '@cosmos-kit/keplr'
import { wallets as cosmostationWallets } from '@cosmos-kit/cosmostation'
import { wallets as leapWallets } from '@cosmos-kit/leap'
import '@interchain-ui/react/globalStyles'
import '@interchain-ui/react/styles'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

export function Providers({ children }: { children: React.ReactNode }) {
  const signerOptions: SignerOptions = {
    // @ts-ignore
    signingStargate: () => {
      return getSigningCosmosClientOptions()
    },
    signingCosmwasm: chain => {
      const chainName = typeof chain === 'string' ? chain : chain.chain_name
      switch (chainName) {
        case 'juno':
          return {
            gasPrice: GasPrice.fromString('0.0025ujuno'),
          }
      }
    },
  }

  return (
    <>
      <ChakraProvider theme={kleoTheme}>
        <ChainProvider
          chains={chains}
          assetLists={assets as AssetList[]}
          wallets={[...keplrWallets, ...leapWallets, ...cosmostationWallets]}
          endpointOptions={{
            isLazy: true,
            endpoints: {
              juno: {
                rpc: chainEndpoint.juno.rpc,
                rest: chainEndpoint.juno.rest,
              },
            },
          }}
          walletConnectOptions={{
            signClient: {
              projectId: '527266e8bac8b6420f2fe25ae52baccc',
              relayUrl: 'wss://relay.walletconnect.org',
              metadata: {
                name: 'KLEO Web App',
                description: 'Kleomedes web app',
                url: 'https://kleomed.es/',
                icons: [],
              },
            },
          }}
          signerOptions={signerOptions}
        >
          <QueryClientProvider client={queryClient}>
            {/* 👇 Here's the script */}
            <ColorModeScript initialColorMode={kleoTheme.initialColorMode} />
            {children}
          </QueryClientProvider>
        </ChainProvider>
      </ChakraProvider>
    </>
  )
}
