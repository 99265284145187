import { extendTheme } from '@chakra-ui/react'

export const defaultThemeObject = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  styles: {
    global: (props: any) => ({
      // Define background colors for both light and dark modes
      body: {
        bgImage:
          props.colorMode === 'light'
            ? "url('/images/background-light.png')"
            : "url('/images/background-dark.png')",
        color: props.colorMode === 'light' ? 'primary.800' : 'white', // Change the colors as needed
      },
    }),
  },
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Mont, sans-serif',
  },
  colors: {
    primary: {
      base: '#371A63',
      '50': '#F1EBFA',
      '100': '#D7C6F0',
      '200': '#BDA2E7',
      '300': '#A47EDD',
      '400': '#8A59D4',
      '500': '#7035CA',
      '600': '#5A2AA2',
      '700': '#432079',
      '800': '#2D1551',
      '900': '#160B28',
    },
    secondary: {
      base: '#25B1FC',
      '50': '#E6F6FF',
      '100': '#B9E6FE',
      '200': '#8BD6FD',
      '300': '#5EC5FD',
      '400': '#31B5FC',
      '500': '#03A5FC',
      '600': '#0384C9',
      '700': '#026397',
      '800': '#014265',
      '900': '#012132',
    },
    tertiary: {
      base: '#9151E0',
      '50': '#F1E9FB',
      '100': '#D9C2F4',
      '200': '#C09BED',
      '300': '#A774E6',
      '400': '#8F4EDF',
      '500': '#7627D8',
      '600': '#5F1FAD',
      '700': '#471782',
      '800': '#2F0F57',
      '900': '#18082B',
    },
  },
  breakPoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  shadows: {
    largeSoft: 'rgba(60, 64, 67, 0.15) 0px 2px 10px 6px;',
  },
}

export const kleoTheme = extendTheme(defaultThemeObject)
